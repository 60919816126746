jQuery(function($) {
    // init the parallax sections. The parallax.js library will do this if we don't
    // but we need to order things carefully, so we explicitly trigger the parallax
    // plugin here first
    $('[data-parallax="scroll"]').parallax();

    // this class is used for styling, see _global.scss
    $('body').addClass('parallax');

    // fade the parallax images in when they've loaded
    $('.parallax-mirror').imagesLoaded()
        .progress( function( instance, image ) {
            setTimeout(function() {
                $(image.img).addClass('image-loaded');
            }, 10);
            //$(image.img).fadeOut(0).fadeIn(2000);
            var result = image.isLoaded ? 'loaded' : 'broken';
        });
});