jQuery(function($){


$('.js-st-form-wizard').each(function(form) {
    var $form = $(this);

    $form.data('step', 1);

    $form.find('[class^=step-]').hide(0);
    $form.find('.step-1').show(0);

    $form.on('click', 'button[data-next]', function(event) {
        var $inputs = $form.find('.step-' + $form.data('step') + " :input, :input[name^=_mesh_form]");
        var data = $inputs.serializeArray();

        data = data.concat([
            {name: "_validate_only", value: true},
            {name: "_step", value: $form.data("step")},
            {name: "action", value: "mesh_form"}
        ]);

        var $btn = $(event.currentTarget);
        $btn.data('label', $btn.html()).html('Please wait...');
        $form.find('button').prop('disabled', true);

        $.ajax({
            url: mesh_wp_config.wp_admin_ajax,
            method: "POST",
            data: $.param(data),
            dataType: 'json',
            success: function(data, status, jqxhr) {
                console.log('submitting step ' + $form.data('step'));
                $btn.html($btn.data('label'));
                $form.find('button').prop('disabled', false);

                $('html,body').animate({scrollTop: $form.offset().top - 60}, 200);

                if(data.success === false) {
                    render_mesh_validation_errors(data, status, jqxhr, $form);
                    return;
                }

                $form.find('[class^=step-]').hide(0);
                $form.data('step', $form.data('step') + 1);
                $form.find('.step-' + $form.data('step')).show(0);
            }
        });
    });



    //var category = st_job_category;
    //var category_id = booking.job_categories[category];
    //var $type = $form.find('[name$=\'[job_type]\']');
    //$type.find('option:not(:first-child)').remove();
    //
    //console.log(category, category_id, booking);
    //$.each(booking.job_types[category_id], function(index, elem) {
    //    $type.append($("<option value=\"" + elem.id + "\">" + elem.jobtype + "</option>"));
    //});
    //$type.val("");




    $form.ajaxForm({
        url: mesh_wp_config.wp_admin_ajax,
        data: {
            action: 'mesh_form'
        },
        dataType: 'json',
        beforeSubmit: function(data, $form) {
            var $btn = $form.find('button[type=submit]');
            $btn.data('label', $btn.html()).html('Please wait...');
            $form.find('button').prop('disabled', true);
        },
        success: function(data, status, jqxhr, $form) {
            var $btn = $form.find('button[type=submit]');
            $btn.html($btn.data('label'));
            $form.find('button').prop('disabled', false);

            if(data.success === false) {
                render_mesh_validation_errors(data, status, jqxhr, $form);
                return;
            }
            $form.find('[class^=step-]').hide(0);
            $form.data('step', $form.data('step') + 1);
            $form.find('.step-' + $form.data('step')).show(0);

            var instance = $form.find('[name=_mesh_form_instance]').val();
            var $field_name = $form.find('[name=\'' + instance + '[first_name]\']');
            var $field_email = $form.find('[name=\'' + instance + '[email]\']');
            var $field_date = $form.find('[name=\'' + instance + '[booking_date]\']');
            var $field_time = $form.find('[name=\'' + instance + '[booking_time]\']');

            $form
                .find('.confirmation-name').text($field_name.val()).end()
                .find('.confirmation-date').text($field_date.val()).end()
                .find('.confirmation-email').text($field_email.val()).end()
                .find('.confirmation-message').text(data.api_response.messages.join(" ")).end()
                .find('.confirmation-reference').text(data.api_response.job_reference_no).end()
            ;

            if(!data.api_response.job_reference_no) {
                $form.find('.confirmation-details').hide(0);
            }


            if($field_time.val().indexOf('-') > 0) {
                $form.find('.confirmation-time').text('between ' + $field_time.val());
            } else {
                $form.find('.confirmation-time').text('at ' + $field_time.val());
            }

            $('html,body').animate({scrollTop: $form.offset().top - 60}, 200);
        }
    });

    $form.on('click', 'button[data-back]', function() {
        $form.find('.error-message').detach();
        $form.find('.has-error').removeClass('has-error');

        $form.find('[class^=step-]').hide(0);
        $form.data('step', $form.data('step') - 1);
        $form.find('.step-' + $form.data('step')).show(0);
    });

});


function render_mesh_validation_errors(data, status, jqxhr, $form) {
    $form.find('.error-message, .success-message').detach();
    $form.find('.has-error').removeClass('has-error');

    if(data.success === false) {
        var instanceId = $form.find('input[name="_mesh_form_instance"]').val();
        var formName = $form.find('input[name="_mesh_form"]').val();

        $.each(data.errors, function(key, value) {
            var $error = $('<div class="error-message"><span class="icon-cancel"></span><span class="text"></span></div>');
            var $field = $form.find("[name='" + instanceId + "[" + key + "]']");

            if (key === '_form') {
                $.each(value, function (i, err) {
                    $error.find('.text').text(err);
                    $form.prepend($error);
                });
            } else if ($field.is(":radio")) {
                $error.find('.text').text(value);
                $form.find("[name='" + instanceId + "[" + key + "]']")
                    .closest('.form-group')
                    .append($error)
                    .addClass('has-error');
            } else {
                $error.find('.text').text(value);
                $form.find("[name='" + instanceId + "[" + key + "]']")
                    .after($error)
                    .closest('.form-group')
                    .addClass('has-error');
            }
        });
    }
}

function render_mesh_form_success(data, status, jqxhr, $form) {
    if(data.success === true) {
        var success_message =  'Thank you for your enquiry. We will get back to you as soon as we can.';
        if($form.data('successMessage')) {
            success_message = $form.data('successMessage');
        }
        $form.append(
            $('<div class="success-message"><span class="icon-checkbox-checked"></span><span class="text">' + success_message + '</span></div>')
        );
    }
}


});